import React, {useState, useEffect} from 'react';
import { Row, Col} from 'react-bootstrap';
import "../styles/home.scss";

function Working() {

  return (
    <div className="working">
      <Row className="align-items-center">
        <Col xs={{span:4,offset:4}} md={{span:3,offset:1}} lg={{span:3,offset:2}}>
          <img src="/icons/TRABAJANDO.png"/>
        </Col>
        <Col xs={12} md={{span:8}} lg={{span:7}}>
          <div className="text-box">
              <h2>Próximamente</h2>
              <p>Pronto tendrás acceso a esta sección,<br/> estamos trabajando en ello.</p>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Working;
