import React, {useState, useEffect} from 'react';
import { Row, Col} from 'react-bootstrap';
import "../styles/home.scss";

function About() {

  return (
    <div className="about">
      <Row className="align-items-center">
        <Col xs={{span:12}} md={{span:10,offset:1}} lg={{span:10,offset:1}}>
          <img src="/images/banner_nostros_web_MW.png" className="w-100"/>
          <Row>
              <Col>
                <h2 className="mt-5 mb-2">HISTORIA</h2>
                <p>La empresa comienza en el año 2015 con la creación de prototipos electrónicos e integración para el sector educativo, más adelante se constituye dando pie a la creación de la marca Megawatt by CEPROELE orientando sus actividades y servicios a la integración de alta especialización y manufactura electrónica. </p>
                <p>A lo largo de este tiempo, Megawatt ha desarrollado artículos y dispositivos que aportan a los sectores de la Ingeniería y Educación.</p>
              </Col>
              <Col>
                <h2 className="mt-5 mb-2">MISIÓN</h2>
                <p>Ofrecemos soluciones de innovación a través del diseño, prototipado, manufactura y/o comercialización de recursos tecnológicos con el fin de optimizar los procesos y resultados industriales, educativos, comerciales y/o profesionales de nuestros clientes.</p>
              </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default About;
