import React, {useState, useEffect} from 'react';
import '../styles/header.scss';
import { Navbar, NavDropdown, Form, FormControl, Button, Nav } from 'react-bootstrap';
import { request } from '../services/Request';

function Header() {

	const [activeTab, setActive] = useState('');
	const routes = ["productos", "distribuidor", ""];
	const [isLogin, setLogin] = useState(false);
	const [urlLogin, setUrl] = useState('');

	useEffect(()=>{
		let currentPath = window.location.pathname.split("/")[1];
		setActive( routes[ routes.indexOf( currentPath ) ] );

		request('/megawatt/urlLogin',{noRedirect:true})
		.then((result:any)=>{
				console.log(result);
			if(result.url){
				setUrl(result.url);
			}
		})

		request('/api/auth/me?token='+localStorage.token,{noRedirect:true})
		.then((result:any)=>{
			if(result.token){
				localStorage.token = result.token;
				setLogin(true);
			}else{
				delete localStorage.token;
				delete localStorage.user;
			}
		})
	},[])

  return (
    <div className="header">
    	<Navbar bg="light" expand={true}>
		  <Navbar.Brand href="/">
		  	<img src="/icons/Logo principal.png" className="logo"/>
		  </Navbar.Brand>		  
		  <Navbar.Collapse id="top-nav">
		    <Nav className="ml-auto">
			    <Form inline>
			      <FormControl type="text" placeholder="Escribe lo que quieres buscar" className="mr-sm-2 nav-font" />
			    </Form>
			    <Nav.Link href="/cotizadores">
			    	<div className="circle-button">
			    		<img src="/icons/Asset 43ldpi.png"/>
			    	</div>	
			    </Nav.Link>
			    {
			    	isLogin ? (
					    <NavDropdown title={ <div className="circle-button"><img src="/icons/Asset 45ldpi.png"/></div> } id="dropdown">
					        <NavDropdown.Item href={"http://"+urlLogin+"/profile?url=megawatt&token="+localStorage.token} className="nav-font">Perfil</NavDropdown.Item>
					        <NavDropdown.Divider />
					        <NavDropdown.Item href="/logout" className="nav-font">Cerrar sesión</NavDropdown.Item>
					    </NavDropdown>
			    	) : (
			    		<Nav.Link href={'http://'+urlLogin+"/login?url=megawatt"}>
					    	<div className="circle-button">
					    		<img src="/icons/Asset 45ldpi.png"/>
					    	</div>	
					    </Nav.Link>
			    	)
			    }
		    </Nav>
		  </Navbar.Collapse>
		</Navbar>
		<Navbar bg="light" expand="lg">
		  <Navbar.Toggle aria-controls="top-nav" className="ml-auto"/>
		  <Navbar.Collapse id="main-nav">
		    <Nav className="ml-auto">
		      <Nav.Link href="/cotizadores/diseno" className="nav-font">Diseño</Nav.Link>
		      <Nav.Link href="/cotizadores/prototipado" className="nav-font">Prototipado</Nav.Link>
		      <Nav.Link href="/cotizadores/volumen" className="nav-font">Manufactura</Nav.Link>
		      <Nav.Link href="/productos" className={"nav-font "+( activeTab == "productos" ? "active" : "" )}>Productos</Nav.Link>		      
		      <Nav.Link href="/" className={"nav-font "+( activeTab == "" ? "active" : "" )}>Inicio</Nav.Link>		      
		    </Nav>
		  </Navbar.Collapse>
		</Navbar>
    </div>
  );
}

export default Header;
