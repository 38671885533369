import React, {useState, useEffect} from 'react';
import '../../styles/products.scss';
import { Row, Col, Carousel, Modal, Button } from 'react-bootstrap';
import { request } from '../../services/Request';
import { useParams } from 'react-router-dom';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

interface Product {
    name:string;
    category:Category;
    description:string;
    long_description:string;
    details:any;
    documents:string[];
    videos:string[];
    url_puntorecarga:string;
    url_mercadolibre:string;
    image:{
        data:{
            full_url:string;
        }
    }
}

interface Category{
    name:string;
    url:string;
    category_parent:any;
    id:string;
}

interface Image{
    image:{
        data:{
            full_url:string;
        }
    };
}

interface Document{
    file:{
        data:{
            full_url:string;
        }
    };
    name:string;
    image:{
        data:{
            full_url:string;
        }
    }
}

interface Video{
    title:string;
    description:string;
    url: string;
}

interface Description{
    title:string;
    description:string;
    image:{
        data:{
            full_url:string;
        }
    };
}

interface RouteParams{
    productId:string;
}


function ProductStarDetail() {

    const [product, setProduct] = useState<Partial<Product>>({
        category:{
            id:'',
            name:'',
            category_parent:{},
            url:''
        },
        details:'<div></div>',
        image:{
            data:{
                full_url:''
            }
        }
    });
    const [images, setImages] = useState<Image[]>([]);
    const [videos, setVideos] = useState<Video[]>([]);
    const [documents, setDocuments] = useState<Document[]>([]);
    const [descriptions, setDescriptions] = useState<Description[]>([]);
    const { productId } = useParams<RouteParams>();
    const [selectedImage, setSelected] = useState("");
    const [currentPage, setPage] = useState(1);
    const [galleryImages, setGallery] = useState<Image[]>([]);
    const [show, setShow] = useState(false);

    useEffect(()=>{
        getProduct();
        getImages();
        getVideos();
        getDocuments();
        getDescriptions();
    },[]);

    const getProduct = () => {
        request("/api/_/items/products?filter[id][eq]="+productId+"&fields=*.*,category.*,category.category_parent.*&limit=1",{})
        .then((result:any)=>{
          if(result.data){
              let data = result.data[0];
              request("/api/_/items/categories?filter[id][eq]="+data.category.category_parent+"&fields=*.*&limit=1",{})
                .then((result:any)=>{
                  if(result.data){
                      data['category']['category'] = result.data;
                      setProduct(data);
                  }
                });
          }
        });
    };

    const getImages = () => {
        request("/api/_/items/product_gallery?filter[product][eq]="+productId+"&fields=*.*",{})
        .then((result:any)=>{
          if(result.data){
              setImages(result.data);
              setGallery( result.data.slice(0, 8) );
              if(result.data.length>0){
                  setSelected( result.data[0].image.data.full_url );
              }
          }
        });
    }

    const getVideos = () => {
        request("/api/_/items/product_videos?filter[product][eq]="+productId+"&fields=*.*",{})
        .then((result:any)=>{
          if(result.data){
              setVideos(result.data);
          }
        });
    }

    const getDocuments = () => {
        request("/api/_/items/product_files?filter[product][eq]="+productId+"&fields=*.*",{})
        .then((result:any)=>{
          if(result.data){
              setDocuments(result.data);
          }
        });
    }

    const getDescriptions = () => {
        request("/api/_/items/star_product_descriptions?filter[product][eq]="+productId+"&fields=*.*",{})
        .then((result:any)=>{
          if(result.data){
              setDescriptions(result.data);
          }
        });
    }

    const showType = (document:string) => {
        return document.substr( document.lastIndexOf('.'), document.length ).toUpperCase();
    }

    const checkRow = (index:number) =>{
        return index % 2 == 0;
    }

    const getVideoYoutube = (url:string) => {
        const urlParams = new URLSearchParams("?"+ url.split('?')[1]);
        return urlParams.get('v') ? urlParams.get('v') : url.substring( url.lastIndexOf("/"), url.length );
    }

    const filterImages = (item:Image, index:number) => {
        let currentLimit = 8 * currentPage;
        return index < currentLimit;
    }

    const changePage = (page:number) => {
        let totalPages = Math.ceil(images.length / 8);
        let newPage = currentPage + page;
        newPage = (newPage < 1 || newPage > totalPages ) ? currentPage : newPage;
        setPage( newPage );
        setGallery( images.slice( 8 * (newPage - 1), 8 * newPage ) );
    }

  return (
    <div className="products-star-detail">
        <Carousel className="pl-0 pr-0">
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={ product.image && product.image.data ? product.image.data.full_url : ''}
                alt="First slide"
              />
            </Carousel.Item>
        </Carousel>
        <Row>
            <Col xs={12} md={{span:8, offset:2}} className="title-square mb-5">
                <h1 className="title-font text-center">{ product.name }</h1>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={{span:10,offset:1}} lg={{span:8, offset:2}}>
                {
                     descriptions.length > 0  &&
                     <div>
                        <h2 className="product-title-font">DESCRIPCIÓN</h2>
                        {
                            descriptions.map((description:Description,index:number)=>(
                                <div>                    
                                    {
                                        checkRow(index) ? (
                                            <Row className="mt-5">
                                                <Col xs={12} md={5}>
                                                    <img src={description.image.data.full_url} className="w-100" />
                                                    <img src="/icons/Asset 46ldpi.png" className="arrow-icon" />
                                                </Col>
                                                <Col xs={12} md={{span:6,offset:1}}>
                                                    <p className="product-subtitle-font">{description.title}</p>
                                                    <p>{description.description}</p>
                                                </Col>
                                            </Row>
                                        ):(
                                            <Row className="mt-5">
                                                <Col xs={12} md={{span:6}}>
                                                    <p className="product-subtitle-font">{description.title}</p>
                                                    <p>{description.description}</p>
                                                </Col>
                                                <Col xs={12} md={{span:5,offset:1}}>
                                                    <img src="/icons/Asset 47ldpi.png" className="arrow-icon-reverse" />
                                                    <img src={description.image.data.full_url} className="w-100" />
                                                </Col>
                                            </Row>
                                        )
                                    }
                                </div>
                            ))
                        }
                         
                     </div>
                }
                {
                     images.length > 0  &&
                     <div>                         
                        <h2 className="product-title-font">GALERÍA</h2>  
                        <br/>
                        <Row>
                            <Col xs={12} md={9}>
                                <img src={selectedImage} className="w-100"/>
                            </Col>
                            <Col xs={12} md={3}>
                                <Row>
                                    {
                                        galleryImages.map((item:Image,index:number)=>(
                                            <Col xs={12} md={6}>
                                                <img src={item.image.data.full_url} className="w-100 mb-2" />
                                            </Col>
                                        ))
                                    }
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <button className="btn btn-link left-icon" onClick={(e) => changePage(-1)}>
                                            <img src="/icons/Flecha boton1.png"/>
                                        </button>
                                    </Col>
                                    <Col xs={6}>
                                        <button className="btn btn-link right-icon" onClick={(e) => changePage(1)}>
                                            <img src="/icons/Flecha boton2.png"/>
                                        </button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row> 
                     </div>
                }
                {
                     (product.details && product.details.length > 0)  &&
                     <div>                        
                        <h2 className="product-title-font">DETALLES TÉCNICOS</h2>    
                        <div className="" dangerouslySetInnerHTML={{__html: product.details}}></div>
                     </div>
                }
                {
                     documents.length > 0  &&
                     <div>                         
                        <h2 className="product-title-font">DOCUMENTOS</h2> 
                        <Row>
                            {
                                documents.map((document:Document,index:number)=>(
                                    <Col xs={12} md={4} className="document">
                                        <img src={document.image.data.full_url} className="w-100"/>
                                        <p className="mt-2">{ document.name }</p>
                                        <a className="btn btn-custom-orange w-100" href={document.file ? document.file.data.full_url : ''}>
                                            Descargar <img src="/icons/Asset 39ldpi.png"/>
                                        </a>
                                    </Col>
                                ))
                            }
                        </Row>
                     </div>
                }
                {
                     descriptions.length > 0  &&
                     <div>                        
                        <h2 className="product-title-font">CONTENIDO ADICIONAL</h2> 
                        {
                            videos.map((video:Video,index:number)=>(
                                <Row className="video">
                                    <Col xs={12} md={4} >                                        
                                        <div className="video-responsive">
                                            <iframe src={"https://www.youtube.com/embed/"+ (getVideoYoutube(video.url)) }></iframe>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={{span:7, offset:1}}>
                                        <p className="video-title">{video.title}</p>
                                        <div className="blue-bar"></div>
                                        <p className="mt-3">{video.description}</p>
                                    </Col>
                                </Row>
                            ))
                        }
                     </div>
                }
                <h2 className="product-title-font">PRODUCTOS RELACIONADOS</h2> 
                <Row>
                    <Col xs={12} md={4} className="video">
                        <a>                            
                            <img src="https://dummyimage.com/600x400/000/fff" className="w-100"/>
                            <p>Producto</p>
                            <div className="blue-bar"></div>
                        </a>
                    </Col>
                </Row>                
            </Col>
        </Row>
        <Row className="align-items-center mt-5 pt-5">
            <Col xs={4} md={{span:2,offset:3}}>
                <a className="btn btn-custom w-100" href={"/productos/"+(product.category ? product.category.category_parent.url : '')+"/"+ (product.category ? product.category.url : '') }>
                  <img src="/icons/Flecha boton1.png" /> Regresar
                </a>
            </Col>
            <Col xs={4} md={2}>
                <img src="/icons/Adquirir_regresar_megawattito.png" className="w-50"/>
            </Col>
            <Col xs={4} md={2}>
                <button className="btn btn-custom w-100" onClick={()=> setShow(true)}>
                  Adquirir <img src="/icons/Flecha boton2.png" />
              </button>
            </Col>
        </Row>
        <Modal show={show} onHide={() => setShow(false)} size="lg">
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="p-5 justify-content-center modal-options">
                    <Col xs={12} className="mb-5">
                        <h2 className="product-extra-font text-center mb-0">GRACIAS POR SU PREFERENCIA</h2>
                        <p className="product-subtitle-font text-center">ADQUIÉRELO EN LA TIENDA DE TU PREDILECCIÓN</p>
                    </Col>
                    {
                        product.url_puntorecarga &&
                        <Col xs={6} md={{span:5}}>
                            <a href={product.url_mercadolibre} className="store_option">
                               <img src="/icons/Mercado_no seleccionado.png" className="w-100 no-hover"/> 
                               <img src="/icons/Mercado libre seleccionado.png" className="w-100 hover"/> 
                            </a>
                        </Col>
                    }
                    {
                        product.url_mercadolibre &&
                        <Col xs={6} md={{span:5}}>
                            <a href={product.url_puntorecarga} className="store_option">
                               <img src="/icons/Recarga_no seleccionado.png" className="w-100 no-hover"/> 
                               <img src="/icons/Punto de recarga seleccionado.png" className="w-100 hover"/> 
                            </a>
                        </Col>
                    }
                </Row>
            </Modal.Body>
          </Modal>
    </div>
  );
}

export default ProductStarDetail;
