import React, {useState, useEffect} from 'react';
import '../../styles/products.scss';
import { Row, Col } from 'react-bootstrap';
import { request } from '../../services/Request';

interface Category {
    image:{
        data:{
            full_url:string;
        }
    };
    description:string;
    name:string;
    url:string;
}

function ProductsMainCategory() {

    const [categories, setCategories] = useState<Category[]>([]);

    useEffect(()=>{
        getCategories();
    }, []);

    const getCategories = () =>{
        request("/api/_/items/categories?filter[category_level][eq]=1&fields=*.*",{})
        .then((result:any)=>{
            if(result.data){
                setCategories(result.data);
            }
        });
    }

    const checkRow = (index:number) =>{
        return index % 2 != 0;
    }

  return (
    <div className="products-main-list pt-5 pb-5">
        <div>
            {
                categories.map((item:Category,index:number)=>(
                    <div className="category">                        
                        {
                            checkRow(index) ? (
                                <Row className="align-items-end">
                                    <Col xs={10} md={{span:6,offset:1}}>
                                        <h2 className="title-font">{ item.name }</h2>
                                        <img src={ item.image.data.full_url } className="w-100" />
                                    </Col>
                                    <Col xs={2} md={2} className="pr-0 d-block d-md-none">
                                        <img src="/icons/2.png" className="w-100" />
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <p className="category-description">{ item.description }</p>
                                        <Row className="justify-content-end">
                                            <Col xs={12} lg={8}>
                                                <a className="btn btn-custom" href={"/productos/"+item.url}>
                                                    Ir a categoría <img src="/icons/Flecha boton2.png" />
                                                </a>
                                            </Col>
                                        </Row>
                                    </Col>                                    
                                    <Col xs={2} md={2} className="pr-0 d-none d-md-block">
                                        <img src="/icons/2.png" className="w-100" />
                                    </Col>
                                </Row>
                            ) : (
                                <Row className="align-items-end">
                                    <Col xs={2} md={2} className="pl-0">
                                        <img src="/icons/1.png" className="w-100" />
                                    </Col>
                                    <Col xs={10} md={6}>
                                        <h2 className="title-font">{ item.name }</h2>
                                        <img src={ item.image.data.full_url } className="w-100" />
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <p className="category-description">{ item.description }</p>
                                        <Row className="justify-content-end">
                                            <Col xs={12} lg={8}>
                                                <a className="btn btn-custom" href={"/productos/"+item.url}>
                                                    Ir a categoría <img src="/icons/Flecha boton2.png" />
                                                </a>
                                            </Col>
                                        </Row>
                                    </Col>                                    
                                </Row>
                            )
                        }
                    </div>
                ))
            }
        </div>
    </div>
  );
}

export default ProductsMainCategory;
