import React from 'react';
import logo from './logo.svg';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from './pages/Home';
import Services from './pages/Services';
import Prototyping from './pages/Prototyping';
import AuthToken from './pages/AuthToken';
import Logout from './pages/Logout';
import About from './pages/About';
import Header from './partials/Header';
import Footer from './partials/Footer';
import DistributorList from './pages/distributor/DistributorList';
import DistributorNew from './pages/distributor/DistributorNew';
import DistributorAbout from './pages/distributor/DistributorAbout';
import ProductsMainCategory from './pages/products/ProductsMainCategory';
import ProductsSubCategory from './pages/products/ProductsSubCategory';
import ProductsLastCategory from './pages/products/ProductsLastCategory';
import ProductDetail from './pages/products/ProductDetail';
import ProductStarDetail from './pages/products/ProductStarDetail';
import PCBQuotes from './pages/quotes/PCBQuotes';
import PrintQuotes from './pages/quotes/PrintQuotes';
import PrototypeQuotes from './pages/quotes/PrototypeQuotes';
import ManufactureQuotes from './pages/quotes/ManufactureQuotes';
import DesignQuotes from './pages/quotes/DesignQuotes';
import CustomQuotes from './pages/quotes/CustomQuotes';
import Working from './pages/Working';

function App() {
  return (
    <Router>
      <div className="App container-fluid p-0">
        <Header />
        <Switch>
          <Route exact path="/">
            <Home/>
          </Route>
          <Route exact path="/nosotros">
            <About/>
          </Route>
          <Route exact path="/servicios">
            <Services/>
          </Route>
          <Route exact path="/prototipado">
            <Prototyping/>
          </Route>
          { /*<Route exact path="/auth/token/:token">
            <AuthToken/>
          </Route>
          <Route exact path="/logout">
            <Logout/>
          </Route>
          <Route exact path="/distribuidor/lista">
            <DistributorList/>
          </Route>
          <Route exact path="/distribuidor/unete">
            <DistributorAbout/>
          </Route>
          <Route exact path="/distribuidor/nuevo">
            <DistributorNew/>
          </Route>*/}
          <Route exact path="/productos">
            <ProductsMainCategory/>
          </Route>
          <Route exact path="/productos/detalle/:productId">
            <ProductDetail/>
          </Route>
          <Route exact path="/productos/estrella/:productId">
            <ProductStarDetail/>
          </Route>
          <Route exact path="/productos/:categoryId">
            <ProductsSubCategory/>
          </Route>
          <Route exact path="/productos/:categoryId/:subcategoryId">
            <ProductsLastCategory/>
          </Route>
          {/*
          <Route exact path="/cotizadores/pcb">
            <PCBQuotes/>
          </Route>
          <Route exact path="/cotizadores/impresion3d">
            <PrintQuotes/>
          </Route>
          <Route exact path="/cotizadores/prototipado">
            <PrototypeQuotes/>
          </Route>
          <Route exact path="/cotizadores/volumen">
            <ManufactureQuotes/>
          </Route>
          <Route exact path="/cotizadores/diseno">
            <DesignQuotes/>
          </Route>
          */}
          <Route exact path="/cotizadores">
            <CustomQuotes/>
          </Route>
          <Route>
            <Working/>
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
