import React, {useState, useEffect} from 'react';
import { Carousel, Row, Col, Button, DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap';
import "../styles/home.scss";
import { request, Result } from '../services/Request';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

interface Image{
  image:{
    data:{
      full_url:string;
    }
  }
}

function Home() {

  const customers = [
    <img src="/images/BAUD.png" className="w-100"/>,
    <img src="/images/ICMA.png" className="w-100"/>,
    <img src="/images/INGENIATE.png" className="w-100"/>,
    <img src="/images/ORMED.png" className="w-100"/>,
    <img src="/images/PRADIOT.png" className="w-100"/>,
    <img src="/images/SANOH.png" className="w-100"/>,
    /*<img src="/images/S BITOL.png" className="w-100"/>,*/
    <img src="/images/TRONICS.png" className="w-100"/>
  ]

  const [images, setImages] = useState<Image[]>([]);

  useEffect(()=>{
    getImages();
  },[]);

  const getImages = () =>{
    request("/api/_/items/main_carousel?limit=6&fields=*.*&filter[status][eq]=published",{})
    .then((results:any)=>{
      if(results.error){

      }else{
        setImages(results.data);
      }
    }).catch((error)=>{
      console.log(error);
    })
  }

  return (
    <div className="home">
      <Carousel>
        {
          images.map((item:Image, index:number)=>(
            <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={ item.image.data.full_url }
                alt="First slide"
              />
            </Carousel.Item>
          ))
        }
      </Carousel>
      <div className="pt-5 pb-5">
        <Row>
          <Col xs={12} md={{offset:1,span:10}} lg={{offset:2,span:8}}>               
            <h2 className="mt-5 mb-5">NUESTROS SERVICIOS</h2>
            <Row className="boxes">
              <Col xs={12} sm={6} md={3} className="box"> 
                <a href="/servicios#diseno">  
                  <div className="image">                  
                    <img src="/images/Categoría1.png" className="w-100"/>
                    <div className="overlay">                      
                      <img src="/icons/Megawattito_diseño.png" className="box-icon"/>
                      <img src="/images/Categoría1_hover.png" className="image-hover"/>
                    </div>
                  </div>               
                  <h3 className="">DISEÑO</h3>
                  <div className="blue-bar">
                  </div>
                  <p>Recibe soporte y seguimiento especializado en el diseño y manufactura del prototipo de tu proyecto a partir de tus diagramas.</p>
                </a>
              </Col>
              <Col xs={12} sm={6} md={3} className="box">  
                <a href="/servicios#prototipado">              
                  <div className="image">                  
                    <img src="/images/Categoría2.png" className="w-100"/>
                    <div className="overlay">                      
                      <img src="/icons/Megawattito_prototipado.png" className="box-icon"/>
                      <img src="/images/Categoría2_hover.png" className="image-hover"/>
                    </div>
                  </div>  
                  <h3 className="">PROTOTIPADO</h3>
                  <div className="blue-bar">
                  </div>
                  <p>Materializamos tus proyectos, mediante la manufactura, ensamble, impresión 3D y más.</p>
                </a>
              </Col>
              <Col xs={12} sm={6} md={3} className="box">    
                <a href="/servicios#volumen">    
                  <div className="image">                  
                    <img src="/images/Categoría3.png" className="w-100"/>
                    <div className="overlay">
                      <img src="/icons/Megawattito_manufactura en volumen.png" className="box-icon"/>
                      <img src="/images/Categoría3_hover.png" className="image-hover"/>
                    </div>
                  </div>        
                  <h3 className="">MANUFACTURA EN VOLUMEN</h3>
                  <div className="blue-bar"></div>
                  <p>Obtén precios de mayoreo en la manufactura y ensamble de productos en masa.</p>
                </a>
              </Col>
              <Col xs={12} sm={6} md={3} className="box">    
                <a href="/productos">
                  <div className="image">                  
                    <img src="/images/Categoría4.png" className="w-100"/>
                    <div className="overlay">
                      <img src="/icons/Megawattito_productos mega.png" className="box-icon"/>
                      <img src="/images/Categoría4_hover.png" className="image-hover"/>
                    </div>
                  </div>  
                  <h3 className="">PRODUCTOS DE MEGAWATT</h3>
                  <div className="blue-bar">
                    
                  </div>
                  <p>Conoce todos los artículos que creamos para ti y los diversos usos que puedes darles.</p>
                </a>            
              </Col>
            </Row>

            <Row className="line-box">
              <Col xs={12} sm={7}>
                <img src="/images/Banner B1_COTIZADOR_inicio_pix x pix_MW copy.png" className="w-100"/>
              </Col>
              <Col xs={12} sm={5}>
                <div className="quotation-text">                  
                  <h2 className="mt-2 mb-2">COTIZADOR</h2>
                  <p>Obtén el precio de tu proyecto de manera personalizada, cotiza PCB, prototipado, diseño, impresión 3D y manufactura en volumen. </p>
                  <div>
                      <DropdownButton
                        as={ButtonGroup}
                        key={'right'}
                        drop={'right'}
                        id={`dropdown-supplier`}
                        variant="link"
                        title={`Ir a cotizador`}
                      >
                        <Dropdown.Item eventKey="1" href="/cotizadores/pcb" >PCB {'>'}</Dropdown.Item>
                        <Dropdown.Item eventKey="2" href="/cotizadores/prototipado">Prototipado {'>'}</Dropdown.Item>
                        <Dropdown.Item eventKey="2" href="/cotizadores/diseno">Diseño {'>'}</Dropdown.Item>
                        <Dropdown.Item eventKey="3" href="/cotizadores/impresion3d" >Impresión 3D {'>'}</Dropdown.Item>
                        <Dropdown.Item eventKey="4" href="/cotizadores/manufactura">Manufactura en volumen {'>'}</Dropdown.Item>
                      </DropdownButton>
                  </div>
                </div>
              </Col>
            </Row>

            <h2 className="mt-5 mb-5">NUESTROS CLIENTES</h2>
            <Row>
              <Col xs={12}>
                 <CarouselProvider totalSlides={customers.length} visibleSlides={6} naturalSlideWidth={100} naturalSlideHeight={100}> 
                    <Slider>
                      {
                        customers.map((item, index:number)=>(
                          <Slide index={index}>{ item }</Slide>
                        ))
                      }
                    </Slider>                   
                    <Row className="mt-4 align-items-center">
                      <Col xs={12} md={8}>
                        <p className="m-0">Escuchamos a cada uno de nuestros clientes y les ofrecemos soluciones creadas a la medida. Gracias por su confianza, nos enorgullece haber participado en sus proyectos.</p>
                      </Col>
                      <Col xs={12} md={{span:2, offset:2}}>
                        <div className="d-flex justify-content-center">
                          <ButtonBack className="btn btn-link btn-carousel">
                            <img src="/icons/Flecha boton1.png"/>
                          </ButtonBack>
                          <ButtonNext className="btn btn-link btn-carousel">
                            <img src="/icons/Flecha boton2.png"/>
                          </ButtonNext>
                        </div>
                      </Col>
                    </Row>
                </CarouselProvider>
              </Col>
            </Row>

            <Row className="line-box">
              <Col xs={12} sm={6}>
                <img src="/images/Banner B2_PRVEDORES_inicio_pix x pix_MW copy.png" className="w-100"/>
              </Col>
              <Col xs={12} sm={6}>
                <div className="distributor-text">                  
                  <h2 className="mt-2 mb-2">DISTRIBUIDORES</h2>
                  <p>Conócelos y localiza al más cercano de tu zona. También puedes convertirte en uno de ellos.</p>
                  <div>
                      <DropdownButton
                        as={ButtonGroup}
                        key={'right'}
                        drop={'right'}
                        id={`dropdown-supplier`}
                        variant="link"
                        title={`Más sobre ellos`}
                      >
                        <Dropdown.Item eventKey="1" href="/distribuidor/lista" >CONÓCELOS {'>'}</Dropdown.Item>
                        <Dropdown.Item eventKey="2" href="/distribuidor/unete">ÚNETELOS {'>'}</Dropdown.Item>
                      </DropdownButton>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Home;
