import React, {useState, useEffect, useRef} from 'react';
import '../../styles/quotes.scss';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { FileDrop } from 'react-file-drop';

function FileModal({ show, handleClose, selectedIndex }:any) {
  
  useEffect(()=>{

  },[]);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles]:any = useState([]);

  const onUpload = (files:any, event:any) => {
    console.log('onDrop!', files, event);
    let fileList = selectedFiles;
    for (var i = files.length - 1; i >= 0; i--) {      
      fileList.push(files[i]);
    }    
    setSelectedFiles([...fileList]);
  };

  const onFileInputChange = (event:any) => {
    console.log(event.target.files);
    let fileList = selectedFiles;
    for (var i = event.target.files.length - 1; i >= 0; i--) {      
      fileList.push(event.target.files[i]);
    }    
    setSelectedFiles([...fileList]);
  }

  const onTargetClick = (event:React.MouseEvent) => {
    event.preventDefault();
    if(fileInputRef.current){
      fileInputRef.current.click()
    }
  }

  const sendFiles = () => {
    handleClose(selectedFiles, selectedIndex);
    setSelectedFiles([]);
  }

  return (
    <div className="quotes-pcb p-0">
      <FileDrop
        onDrop={onUpload}
      >
        <Modal size="lg" show={show} onHide={handleClose} className="custom-modal">
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <h2 className="text-center">¡Suelta aquí tus archivos o archivo!<br/> o <a href="#" className="subtitle-font" onClick={onTargetClick}>haz clic aquí</a> para seleccionarlos desde tu PC</h2>
            <p className="text-center text-blue">Recuerda ver las especificaciones de archivo</p>
            <Row className="justify-content-center">
              <Col xs={12} md={3}>
                <input
                  onChange={onFileInputChange}
                  ref={fileInputRef}
                  type="file"
                  multiple
                  className="hidden"
                />
                
                  <img src="/icons/Asset 48.png" className="w-100 mt-2"/>              
              </Col>
              <Col xs={{span:8,offset:2}}>
                <p className="mt-3 text-left"><b>Archivos a subir:</b></p>
                 <ul className="text-left">
                   {
                     selectedFiles.map( (item:any,index:number)=>(
                       <li>{item.name}</li>
                      ))
                   }
                 </ul> 
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row className="justify-content-center w-100 mb-5">
              <Col xs={12} md={4}>              
                <button type="submit" className="btn btn-custom-blue w-100" onClick={sendFiles}>
                  Archivos listos <img src="/icons/Flecha boton2.png"/> 
                </button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </FileDrop>
    </div>
  );
}

export default FileModal;
