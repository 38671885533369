import React, {useState, useEffect} from 'react';
import { Row, Col} from 'react-bootstrap';
import "../styles/home.scss";

function Services() {

  return (
    <div className="services">
      <Row className="align-items-center section" id="diseno">
        <Col xs={{span:12}} md={{span:4,offset:1}}>
            <p className="text-orange m-0">Nuestros servicios</p>
            <h2 className="mb-3">DISEÑO</h2>
          <p>Podemos apoyarte a convertir tu idea en una realidad. <br/>
Nuestro servicio de diseño consta de ayudar a nuestros clientes a crear, diseñar y manufacturar productos de base tecnológica, comenzando desde una arquitectura sólida y pasando por cada una de las etapas del diseño y prototipado hasta crear un artículo funcional listo para ser comercializado. <br/>
El diseño y manufactura de artículos tecnológicos requiere de una amplia experiencia en normatividad y estándares internacionales, así como pruebas de confiabilidad para poder cumplir con dichos requerimientos. </p>
          <div className="mt-5 mb-5">
              <Row>
                  <Col xs={12} md={6}>
                    <a className="btn btn-custom w-100" href={"/diseno"}>
                        Ver más <img src="/icons/Flecha boton2.png" />
                    </a>
                  </Col>
                  <Col xs={12} md={6}>
                    <a className="btn btn-custom w-100" href={"/cotizadores/diseno"}>
                        Ir a Cotizador <img src="/icons/Flecha boton2.png" />
                    </a>
                  </Col>
              </Row>            
          </div>
        </Col>
        <Col xs={12} md={{span:4, offset:2}}>
          <div className="text-box">
              <img src={"/images/Secciones_diseño.png"} className="w-100"/>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center section" id="prototipado">
        <Col xs={{span:12}} md={{span:4,offset:1}}>
            <p className="text-orange m-0">Nuestros servicios</p>
            <h2 className="mb-3">PROTOTIPADO</h2>
          <p>El uso de prototipos, tanto en la industria grande como en las pequeñas empresas y entre los emprendedores, es cada vez más común. Esto responde a la necesidad de hacer validaciones sin la necesidad de tener un capital enorme.</p>
          <div className="mt-5 mb-5">
              <Row>
                  <Col xs={12} md={6}>
                    <a className="btn btn-custom w-100" href={"/prototipado"}>
                        Ver más <img src="/icons/Flecha boton2.png" />
                    </a>
                  </Col>
                  <Col xs={12} md={6}>
                    <a className="btn btn-custom w-100" href={"/cotizadores/prototipado"}>
                        Ir a Cotizador <img src="/icons/Flecha boton2.png" />
                    </a>
                  </Col>
              </Row>            
          </div>
        </Col>
        <Col xs={12} md={{span:4, offset:2}}>
          <div className="text-box">
              <img src={"/images/Secciones_prototipado.png"} className="w-100"/>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center section" id="volumen">
        <Col xs={{span:12}} md={{span:4,offset:1}}>
            <p className="text-orange m-0">Nuestros servicios</p>
            <h2 className="mb-3">MANUFACTURA EN VOLUMEN</h2>
          <p>Manufacturar en volumen requiere una gran cantidad de fixturas, pokayokes y otros sistemas de control en la producción que garanticen la calidad de tu producto. <br/>
Estamos preparados para recibir tu proyecto y manufacturarlo con gran calidad.</p>
          <div className="mt-5 mb-5">
              <Row>
                  <Col xs={12} md={6}>
                    <a className="btn btn-custom w-100" href={"/volumen"}>
                        Ver más <img src="/icons/Flecha boton2.png" />
                    </a>
                  </Col>
                  <Col xs={12} md={6}>
                    <a className="btn btn-custom w-100" href={"/cotizadores/volumen"}>
                        Ir a Cotizador <img src="/icons/Flecha boton2.png" />
                    </a>
                  </Col>
              </Row>            
          </div>
        </Col>
        <Col xs={12} md={{span:4, offset:2}}>
          <div className="text-box">
              <img src={"/images/manufactura-en-volumen.png"} className="w-100"/>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Services;
