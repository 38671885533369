import React, {useState, useEffect, useRef} from 'react';
import '../../styles/quotes.scss';
import { Row, Col, Modal, Button } from 'react-bootstrap';

function SentQuoteModal({ show, handleClose }:any) {
  
  useEffect(()=>{

  },[]);

  return (
    <div className="quotes-pcb p-0">
      <Modal size="lg" show={show} onHide={handleClose} className="custom-modal">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <Row className="align-items-center">
            <Col xs={12} md={{span:5,offset:1}}>
              <img src="/icons/GRACIAS 02.png" className="sent-logo"/>
            </Col>
            <Col xs={12} md={{span:5}}>
                <h1 className="text-orange text-center">Gracias</h1>
                <h2 className="text-blue text-center">te contactaremos a la brevedad.</h2>
            </Col>
          </Row>
          <br/>
          <br/>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SentQuoteModal;
