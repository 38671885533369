import React from 'react';
import '../styles/footer.scss';
import { Row, Col } from 'react-bootstrap';

function Footer() {
  return (
    <div className="footer pt-5 pb-5">
    	<Row>
    		<Col xs={12} sm={12} md={12} lg={{span:7, offset:1}}>
    			<Row className="align-items-end">    				
		    		<Col xs={12} sm={12} md={{span:3}}>
		    			<img src="/icons/Logo footer.png" className="logo-footer"/>
		    		</Col>
		    		<Col xs={12} sm={12} md={{span:8, offset:1}}>
		    			<Row>
		    				<Col xs={5} className="d-flex">
		    					<img src="/icons/Datos_footer 01.png" className="icon-footer"/> 
		    					<p> <b>(449) 361 55 36</b></p>
		    				</Col>
		    				<Col xs={7} className="d-flex">
		    					<img src="/icons/Datos_footer 03.png" className="icon-footer"/> 
		    					<p> <b>servicio@megawatt.com.mx</b></p>
		    				</Col>
		    				<Col xs={12} className="d-flex mt-4">
		    					<img src="/icons/Datos_footer 02.png" className="icon-footer"/> 
		    					<p> <b>Av. de la Convención de 1914 Norte #2301 Local D-39A, 2do Piso. Col. San Cayetano, Ags, México</b></p>
		    				</Col>
		    			</Row>
		    		</Col>
    			</Row>
    		</Col>
    		<Col xs={12} sm={12} md={12} lg={{span:1, offset:1}}>
    			<div className="d-flex social-icons">
    				<a href="https://www.facebook.com/MegawattMx" target="_blank">
    					<img src="/icons/Face_footer.png" className="icon-footer"/>
    				</a>
    				{ /*<a href="https://www.youtube.com/channel/UCCCKfzysgRnPH050Ik0W6Vw?view_as=subscriber" target="_blank">
    					<img src="/icons/Youtube_footer.png" className="icon-footer"/>
    				</a> */ }
    				<a href="https://www.instagram.com/megawatt_mx/?hl=es-la" target="_blank">
    					<img src="/icons/Instagram_footer.png" className="icon-footer"/>
    				</a>
                    <a href="https://www.linkedin.com/company/64689781/admin/" target="_blank">
                        <img src="/icons/linkedin_footer.png" className="icon-footer"/>
                    </a>
    				{ /*<a href="https://www.facebook.com/megawattags" target="_blank">
    					<img src="/icons/twitter_footer.png" className="icon-footer"/>
    				</a> */ }
    			</div>
    		</Col>
    		<Col xs={12} sm={{span:10, offset:1}}>
    			<hr/>
    			<div className="d-flex subfooter justify-content-center">
    				<a>Términos y condiciones</a>
    				<a>Aviso de privacidad</a>
    				{/*<a>Aviso legal</a>
    				<a>Políticas de privacidad</a>
    				<a>Facturaciones</a>*/}
    				<a href="/cotizadores">Atención a clientes</a>
    				<a href="/nosotros">Nosotros</a>
    			</div>
    		</Col>
    	</Row>
    </div>
  );
}

export default Footer;
