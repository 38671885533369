import React, {useState, useEffect} from 'react';
import '../../styles/quotes.scss';
import { Row, Col, Form, Card } from 'react-bootstrap';
import { request } from '../../services/Request';
import { CaretDown, QuestionCircleFill } from 'react-bootstrap-icons';
import FileModal from './FileModal';
import SentQuoteModal from './SentQuoteModal';
import UserModal from './UserModal';
import Loader from 'react-loader-spinner';

interface Quote{
  name:string;
  email:string;
  comments:string;
  type:string;
  [key: string]: any;
}

function CustomQuotes() {

  const [captcha, setCaptcha] = useState({
    data:'',
    text:''
  });

  const [showModal, setModal] = useState(false);
  const [showSentModal, setSentModal] = useState(false);
  const [showUserModal, setUserModal] = useState(false);
  const [selectedFiles, setFiles]:any = useState([]);
  const [quote, setQuote] = useState<Partial<Quote>>({
    type:'Otro'
  });
  const [loading, showLoading] = useState(false);
  
    useEffect(()=>{
      getCaptcha();
    },[]);

    const getCaptcha = () => {
      request('/megawatt/captcha',{})
      .then((result:any)=>{
        setCaptcha(result);
      });
    }

    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
      console.log(e.target.value);
      let data = quote;
      data[e.target.name] = e.target.value;
      setQuote(data);
    }

    const getColorLabel = (color:string, label:string)=>{
      return (
        <div className="d-flex align-items-center">
          <div className={"color-square "+color+"-square"}></div>
          <p className="m-0">{label}</p>
        </div>);
    }

    const handleClose = (files?:any, index?:any) =>{
      if(files){
        setFiles(files);
      }
      setModal(false);
    };

    const handleSentClose = () =>{      
      setSentModal(false);
      window.location.reload();
    };

    const handleUserClose = (user:any) =>{      
      console.log(user);
      setUserModal(false);
    };

    const submitHandler = (event:React.FormEvent) => {
      event.preventDefault();
      if(captcha.text != quote.code){
        alert('El captcha no coincide.');
      }else if(!quote.name && !quote.email && !quote.comments){
        alert('Es necesario completar todos los campos del formulario.');  
      }else{      
        showLoading(true);
        let files:any[] = [];
        (function getFile(){
          if(selectedFiles.length > 0){
            let file = selectedFiles.splice(0,1)[0];
            let data = new FormData();
            data.append("file", file);
            request('https://mw.designa.mx/api/_/files',{
              method:'POST',
              file: true,
              body: data
            })
            .then((results:any)=>{
              console.log(results);
              if(results.error){
                alert('Hubo un error subiendo uno de los archivos.');
              }else{
                files.push(results.data);
                getFile();
              }
            })
          }else{
            delete quote['code'];
            request('https://mw.designa.mx/api/_/items/simple_quotes',{
              method:'POST',
              body: JSON.stringify( quote )
            })
            .then((results:any)=>{
              console.log(results);
              if(results.error){
                alert('Ocurrió un error al realizar el proceso.');
              }else{
                if(files.length > 0){
                  (function getnewFile(){
                    if(files.length > 0){
                      let file = files.splice(0,1)[0];
                      request('https://mw.designa.mx/api/_/items/quotes_files',{
                        method:'POST',
                        body: JSON.stringify({
                          simple_quotes_id: results.data.id,
                          directus_files_id: file.id
                        })
                      })
                      .then((results:any)=>{
                        console.log(results);
                        if(results.error){
                          alert('Ocurrió un error al realizar el proceso.');
                        }else{
                          getnewFile();
                        }
                      })
                    }else{
                      setSentModal(true);
                      showLoading(false);
                    }
                  })();
                }else{
                  setSentModal(true);
                  showLoading(false);
                }
              }
            })
          }
        })();
      }
    }


  return (
    <div className="quotes-pcb">
      {
        loading &&
        <div className="loader">
          <Loader
             type="Oval"
             color="#e6520f"
             height={300}
             width={300}
          />
        </div>
      }
      <Form onSubmit={submitHandler}>
        <Row>
          <Col xs="12" md={{offset:2, span:8}}>
            <Row className="align-items-center">
              <Col xs="12" sm={12} md="12" lg={6}>
                <h2 className="mt-0 mb-5">Contáctanos</h2>
                <p>Antes de ponernos en contacto, te sugerimos verificar si su solicitud corresponde a 
                alguna de estas categorías para atenderte de forma más ágil, si no es así, sigue con tu mensaje 
                en esta sección.</p>    
              </Col>
              <Col xs="12" sm={12} md={{span:12}} lg={6}>
                <Row>
                  <Col xs="12" md="6">
                    <a href="/cotizadores/pcb" className="btn btn-custom-blue w-100 no-center d-flex align-items-center">
                      PCB <img src="/icons/Flecha boton2.png"/> 
                    </a>
                  </Col>
                  <Col xs="12" md="6">
                    <a href="/cotizadores/prototipado" className="d-flex align-items-center btn btn-custom-blue w-100 no-center">
                      Prototipado <img src="/icons/Flecha boton2.png"/> 
                    </a>
                  </Col>
                  <Col xs="12" md="6">
                    <a href="/cotizadores/impresion3d" className="d-flex align-items-center btn btn-custom-blue w-100 no-center">
                      Impresión 3D <img src="/icons/Flecha boton2.png"/> 
                    </a>
                  </Col>
                  <Col xs="12" md="6">
                    <a href="/cotizadores/volumen" className="d-flex align-items-center btn btn-custom-blue w-100 no-center d-flex align-items-center">
                      <span>Manufactura<br/> en volumen</span> <img src="/icons/Flecha boton2.png" className="ml-auto"/> 
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm={12} md={{offset:2, span:8}}>
            <Row className="align-items-center">
              <Col xs="12" md="12" lg={7}>
                <h2 className="mt-5 mb-5">Déjanos tu mensaje</h2>
                <Form.Group controlId="name">
                  <Form.Label>Nombre completo</Form.Label>
                  <Form.Control required type="text" placeholder="Ingrese su nombre completo" name="name" onChange={onChange} className="custom-input-text"/>
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label>Correo electrónico</Form.Label>
                  <Form.Control required type="text" placeholder="Ingrese su correo electrónico" name="email" onChange={onChange} className="custom-input-text"/>
                </Form.Group>
                  <Form.Group controlId="comments">
                    <Form.Control required as="textarea" className="custom-textarea" rows={3} placeholder="Escríbenos aquí" name="comments" onChange={onChange}/>
                  </Form.Group>    
              </Col>
              <Col xs="12" sm={12} md={{span:12}} lg={4}>
                <label>Opcional para adjuntar a tu mensaje.</label>                
                <div className="text-right">                  
                  <button type="button" className="btn btn-custom-orange w-100 pt-3 pb-3" onClick={()=> setModal(true)}>
                    Subir ARCHIVO <img src="/icons/Asset 39ldpi.png"/>
                  </button>                  
                </div>  
                <div id="captcha" className="text-left mt-3">                                    
                  <div dangerouslySetInnerHTML={{ __html: captcha.data }}></div>
                </div>
                <div className="text-left">                  
                  <button type="button" className="btn btn-orange" onClick={getCaptcha}>Cambiar imagen</button>
                </div>
                <Form.Group controlId="code">
                  <Form.Label>Código de seguridad</Form.Label>
                  <Form.Control type="text" 
                    placeholder="Ingrese el código de seguridad" name="code" 
                    onChange={onChange} className="custom-input-text"/>
                </Form.Group>             
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col xs={6} md={3} lg={2}>
            <img src="/icons/Asset 39.png" className="w-75"/>
          </Col>
          <Col xs={6} md={3} lg={2}>
            <button type="submit" className="btn btn-custom-blue w-100 mt-5">
              Enviar mensaje <img src="/icons/Flecha boton2.png"/> 
            </button>
          </Col>
        </Row>
      </Form>
      <FileModal show={showModal} handleClose={handleClose}/>
      <SentQuoteModal show={showSentModal} handleClose={handleSentClose}/>
      <UserModal show={showUserModal} handleClose={handleUserClose}/>
    </div>
  );
}

export default CustomQuotes;
