import React, {useState, useEffect} from 'react';
import '../../styles/products.scss';
import { Row, Col } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { request } from '../../services/Request';
import { useParams } from 'react-router-dom';

interface Category {
    name:string;
    id:string;
}

interface Product{
    name: string;
    image:{
      data:{
        full_url:string;
      }
    };
    star:boolean;
    id:string;
}

interface RouteParams{
  subcategoryId:string;
}

function ProductsLastCategory() {

    const [category, setCategory] = useState<Partial<Category>>({
        name: ""
    });
    const [products, setProducts] = useState<Product[]>([]);
    const { subcategoryId } = useParams<RouteParams>();
    const [total, setTotal] = useState(0);
  
    useEffect(()=>{
      getCategory();
    },[]);

    const getCategory = () => {
      request("/api/_/items/categories?filter[url][eq]="+subcategoryId+"&fields=*.*&limit=1",{})
        .then((result:any)=>{
          if(result.data){
            setCategory(result.data[0]);
            request("/api/_/items/products?filter[category][eq]="+result.data[0].id+"&fields=*.*&limit=16&meta=*",{})
            .then((result:any)=>{
                if(result.data){
                   setProducts(result.data);
                }
                if(result.meta){
                  setTotal( result.meta.result_count );
                }
            });
          }
        });
    }

    const changePage = (page:any) => {
        console.log(page);
        request("/api/_/items/products?filter[category][eq]="+category.id+"&fields=*.*&limit=16&offset="+( page > 1 ? (page-1) * 16 : 0),{})
        .then((result:any)=>{
            if(result.data){
               setProducts(result.data);
            }
        });
    }

  return (
    <div className="products-last-list pb-5">
        <Row>
            <Col xs={12} md={{offset:2, span:8}}>                
                <h2 className="title-font">{category.name}</h2>
                <Row>
                    { products.map((subitem:Product,subindex:number)=>(
                          <Col xs={12} md={4} lg={3} className="product">
                              <a href={ subitem.star ? "/productos/estrella/"+subitem.id : "/productos/detalle/"+subitem.id}>                                  
                                  <div className="image border-image" style={{background: 'url('+subitem.image.data.full_url+')'}}>
                                  </div>
                                  <p className="">{subitem.name}</p>
                                  <div className="blue-bar"></div>
                              </a>
                          </Col>
                      ))
                    }
                </Row>
                <br/>
                <div className="d-flex justify-content-center">                    
                    <ReactPaginate
                      previousLabel={<img src="/icons/Flecha boton1.png" className="arrow-icon"/>}
                      nextLabel={<img src="/icons/Flecha boton2.png" className="arrow-icon"/>}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={ total }
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={changePage}
                      containerClassName={'pagination'}
                      activeClassName={'active'}
                    />
                </div>
            </Col>
        </Row>
    </div>
  );
}

export default ProductsLastCategory;
