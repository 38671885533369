import React, {useState, useEffect} from 'react';
import '../../styles/products.scss';
import { Row, Col } from 'react-bootstrap';
import { request } from '../../services/Request';
import { useParams } from 'react-router-dom';

interface Category {
    image:{
        data:{
            full_url:string;
        }
    };
    long_description:string;
    name:string;
    url:string;
    id:string;
}

interface Product {
    image:{
        data:{
            full_url:string;
        }
    };
    name:string;
    star:boolean;
    id:string;
}

interface SubCategory{
    categoryname: string;
    url: string;
    products: Product[];
}

interface RouteParams {
  categoryId: string
}

function ProductsSubCategory() {

    const [category, setCategory] = useState<Partial<Category>>({
        image:{
            data:{
                full_url: ''
            }
        }
    });
    const [subcategories, setSubcategories] = useState<SubCategory[]>([]);
    const { categoryId } = useParams<RouteParams>();

    useEffect(()=>{
        getCategory();
    },[]);

    const getCategory = () => {
        request("/api/_/items/categories?filter[url][eq]="+categoryId+"&fields=*.*&limit=1",{})
        .then((result:any)=>{
            if(result.data){
                setCategory(result.data[0]);
                request("/api/_/items/categories?filter[category_parent][eq]="+result.data[0].id+"&fields=*.*",{})
                .then((result:any)=>{
                    if(result.data){
                        let items: SubCategory[] = [];
                        (function getItem(){
                            if(result.data.length > 0){
                                let item = result.data.splice(0,1)[0];
                                request("/api/_/items/products?filter[category][eq]="+item.id+"&fields=*.*&limit=7",{})
                                .then((result:any)=>{
                                    if(result.data){
                                        items.push({
                                            categoryname: item.name,
                                            products: result.data,
                                            url: item.url
                                        });
                                    }
                                    getItem();
                                });
                            }else{
                                setSubcategories(items);
                            }
                        })();
                    }
                });
            }
        });
    }

  return (
    <div className="products-sub-list pb-5">
        <Row className="banner align-items-center">
            <Col xs={12} md={{offset:1,span:10}} className="h-100">
              <div className="image-banner" style={{background: 'url('+ ( category.image ? category.image.data.full_url : '')+')'}}>
                  <div className="overlay">
                      <h1>{category.name}</h1>
                  </div>
              </div>      
            </Col>
            <Col xs={3} md={{span:3}} className="icon-banner pr-0 d-none d-md-block">
                <img src="/icons/Asset 48ldpi.png" className="w-100"/>     
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={{offset:1,span:10}}>
                <p className="category-description">{ category.long_description }</p>
            </Col>
        </Row>
        {
            subcategories.map( (item:SubCategory,index:number)=>(
                <Row>
                    <Col xs={12} md={{offset:1,span:10}}>
                        <h2 className="title-font">{item.categoryname}</h2>
                        <Row>
                            { item.products.map((subitem:Product,subindex:number)=>(
                                  <Col xs={12} md={4} lg={3} className="product">
                                      <a href={ subitem.star ? "/productos/estrella/"+subitem.id : "/productos/detalle/"+subitem.id}>    
                                          <div className="image border-image" style={{background: 'url('+subitem.image.data.full_url+')'}}>
                                      </div>
                                          <p className="">{subitem.name}</p>
                                          <div className="blue-bar"></div>
                                        </a>
                                  </Col>
                              ))
                            }
                            <Col xs={12} md={4} lg={3} className="product">
                                  <div className="image image-icon" style={{background: 'url("/icons/Ir a catalogo megawatitito.png")'}}>
                                  </div>
                                  <a className="btn btn-custom" href={"/productos/"+category.url+"/"+item.url}>
                                      Ir a catálogo <img src="/icons/Flecha boton2.png" />
                                  </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>                
            ))
        }
    </div>
  );
}

export default ProductsSubCategory;
