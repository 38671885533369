import React, {useState, useEffect} from 'react';
import '../../styles/products.scss';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { request } from '../../services/Request';
import { useParams } from 'react-router-dom';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

interface Product {
    name:string;
    category:Category;
    description:string;
    long_description:any;
    details:any;
    documents:string[];
    videos:string[];
    url_puntorecarga:string;
    url_mercadolibre:string;
    image:{
        data:{
            full_url:string;
        }
    }
}

interface Category{
     name:string;
    url:string;
    category_parent:any;
    id:string;
}

interface Image{
    image:{
        data:{
            full_url:string;
        }
    }
}

interface Document{
    file:{
        data:{
            full_url:string;
        }
    };
}

interface Video{
    title:string;
    description:string;
    url: string;
}

interface RouteParams{
    productId:string;
}

function ProductDetail() {

    const [product, setProduct] = useState<Partial<Product>>({
        category:{
            id:'',
            name:'',
            category_parent:{},
            url:''
        },
        details:'<div></div>'
    });
    const [images, setImages] = useState<Image[]>([]);
    const [videos, setVideos] = useState<Video[]>([]);
    const [documents, setDocuments] = useState<Document[]>([]);
    const { productId } = useParams<RouteParams>();
    const [show, setShow] = useState(false);

    useEffect(()=>{
        getProduct();
        getImages();
        getVideos();
        getDocuments();
    },[]);

    const getProduct = () => {
        request("/api/_/items/products?filter[id][eq]="+productId+"&fields=*.*,category.*,category.category_parent.*&limit=1",{})
        .then((result:any)=>{
          if(result.data){
            let data = result.data[0];
              setProduct(data);
          }
        });
    };

    const getImages = () => {
        request("/api/_/items/product_gallery?filter[product][eq]="+productId+"&fields=*.*",{})
        .then((result:any)=>{
          if(result.data){
              setImages(result.data);
          }
        });
    }

    const getVideos = () => {
        request("/api/_/items/product_videos?filter[product][eq]="+productId+"&fields=*.*",{})
        .then((result:any)=>{
          if(result.data){
              setVideos(result.data);
          }
        });
    }

    const getDocuments = () => {
        request("/api/_/items/product_files?filter[product][eq]="+productId+"&fields=*.*",{})
        .then((result:any)=>{
          if(result.data){
              setDocuments(result.data);
          }
        });
    }

    const showType = (document:string) => {
        return document.substr( document.lastIndexOf('.'), document.length ).toUpperCase();
    }

    const getVideoYoutube = (url:string) => {
        const urlParams = new URLSearchParams("?"+ url.split('?')[1]);
        return urlParams.get('v') ? urlParams.get('v') : url.substring( url.lastIndexOf("/"), url.length );
    }

  return (
    <div className="products-detail pt-5">
        <Row>
            <Col xs={{span:10,offset:1}} md={{span:4,offset:1}}>
                <img src={product.image ? product.image.data.full_url : ''} className="w-100 mb-5"/>
            </Col>
            <Col xs={{span:12}} md={{span:5,offset:1}}>
                <h2 className="product-title-font mt-0">{ product.name }</h2>
                <p className="product-subtitle-font">{ (product.category ? product.category.category_parent.name : '')+" - "+ (product.category ? product.category.name : '') }</p>
                <p>{product.description}</p>
                <br/>
                <CarouselProvider totalSlides={images.length} visibleSlides={3} naturalSlideWidth={100} naturalSlideHeight={100}> 
                    <Row className="align-items-center">
                        <Col xs={1} md={2}>                        
                            <ButtonBack className="btn btn-link btn-carousel">
                               <img src="/icons/Flecha boton1.png"/>
                            </ButtonBack>
                        </Col>
                        <Col xs={10} md={8}>                        
                                <Slider>
                                  {
                                    images.map((item:Image, index:number)=>(
                                      <Slide index={index}>
                                        <img src={item.image.data.full_url} className="w-100"/>
                                      </Slide>
                                    ))
                                  }
                                </Slider> 
                        </Col>
                        <Col xs={1} md={2}>
                          <ButtonNext className="btn btn-link btn-carousel">
                            <img src="/icons/Flecha boton2.png"/>
                          </ButtonNext>                        
                        </Col>
                    </Row>
                </CarouselProvider>
            </Col>
        </Row>
        <Row>
            <Col xs={{span:12}} md={{span:10,offset:1}} lg={{span:8,offset:2}}>
                {
                    (product.long_description && product.long_description.length > 0) &&
                    <div>                        
                        <h2 className="product-title-font">DESCRIPCIÓN</h2>    
                        <div className="" dangerouslySetInnerHTML={{__html: product.long_description}}></div>
                    </div>
                }
                {
                    (product.details && product.details.length > 0) &&
                    <div>                        
                        <h2 className="product-title-font">DETALLES TÉCNICOS</h2>    
                        <div className="" dangerouslySetInnerHTML={{__html: product.details}}></div>
                    </div>
                }
                {
                    (documents.length > 0) &&
                    <div>                        
                        <h2 className="product-title-font">DOCUMENTOS</h2> 
                        <Row>
                            {
                                documents.map((document:Document,index:number)=>(
                                    <Col xs={12} md={4}>
                                        <button className="btn btn-custom-orange w-100">
                                            Descargar { showType(document.file ? document.file.data.full_url : '') } <img src="/icons/Asset 39ldpi.png"/>
                                        </button>
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>
                }
                {
                    (videos.length > 0) &&
                    <div>                        
                        <h2 className="product-title-font">CONTENIDO ADICIONAL</h2> 
                        <Row>
                            {
                                videos.map((video:Video,index:number)=>(
                                    <Col xs={12} md={4} className="video">
                                        <div className="video-responsive">
                                            <iframe src={"https://www.youtube.com/embed/"+ (getVideoYoutube(video.url)) }></iframe>
                                        </div>
                                        <p>{video.title}</p>
                                        <div className="blue-bar"></div>
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>
                }
                <h2 className="product-title-font">PRODUCTOS RELACIONADOS</h2> 
                <Row>
                    <Col xs={12} md={4} className="video">
                        <a>                            
                            <img src="https://dummyimage.com/600x400/000/fff" className="w-100"/>
                            <p>Producto</p>
                            <div className="blue-bar"></div>
                        </a>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row className="align-items-center mt-5 pt-5">
            <Col xs={4} md={{span:2,offset:3}}>
                <a className="btn btn-custom w-100" href={"/productos/"+(product.category ? product.category.category_parent.url : '')+"/"+ (product.category ? product.category.url : '') }>
                  <img src="/icons/Flecha boton1.png" /> Regresar
                </a>
            </Col>
            <Col xs={4} md={2}>
                <img src="/icons/Adquirir_regresar_megawattito.png" className="w-50"/>
            </Col>
            <Col xs={4} md={2}>
                <button className="btn btn-custom w-100" onClick={()=> setShow(true)}>
                  Adquirir <img src="/icons/Flecha boton2.png" />
              </button>
            </Col>
        </Row>
        <Modal show={show} onHide={() => setShow(false)} size="lg">
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="p-5 justify-content-center modal-options">
                    <Col xs={12} className="mb-5">
                        <h2 className="product-extra-font text-center mb-0">GRACIAS POR SU PREFERENCIA</h2>
                        <p className="product-subtitle-font text-center">ADQUIÉRELO EN LA TIENDA DE TU PREDILECCIÓN</p>
                    </Col>
                    {
                        product.url_puntorecarga &&
                        <Col xs={6} md={{span:5}}>
                            <a href={product.url_mercadolibre} className="store_option">
                               <img src="/icons/Mercado_no seleccionado.png" className="w-100 no-hover"/> 
                               <img src="/icons/Mercado libre seleccionado.png" className="w-100 hover"/> 
                            </a>
                        </Col>
                    }
                    {
                        product.url_mercadolibre &&
                        <Col xs={6} md={{span:5}}>
                            <a href={product.url_puntorecarga} className="store_option">
                               <img src="/icons/Recarga_no seleccionado.png" className="w-100 no-hover"/> 
                               <img src="/icons/Punto de recarga seleccionado.png" className="w-100 hover"/> 
                            </a>
                        </Col>
                    }
                </Row>
            </Modal.Body>
          </Modal>
    </div>
  );
}

export default ProductDetail;
