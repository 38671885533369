import React, {useState, useEffect, useRef} from 'react';
import '../../styles/quotes.scss';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';

interface User{
  user_name:string;
  user_email:string;
  user_phone:string;
  [key:string]:string;
}

function UserModal({ show, handleClose }:any) {
  
  useEffect(()=>{

  },[]);

  const [user, setUser] = useState<Partial<User>>({});

  const sendData = (e:React.FormEvent) => {
    e.preventDefault();
    handleClose(user);
  }

  const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    let data = user;
    data[e.target.name] = e.target.value;
    setUser(data);
  }

  return (
    <div className="quotes-pcb p-0">
      <Modal size="lg" show={show} onHide={handleClose} className="custom-modal">
        <Form onSubmit={sendData}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <h2 className="text-center text-blue mb-5">Compártenos algunos datos para seguir en comunicación</h2>
          <Row className="justify-content-center">
            <Col xs={12} md={{span:7}}>
              <Form.Group controlId="user_name" className="d-flex align-items-center">
                <Form.Label className="col-3">Nombre*</Form.Label>
                <Form.Control className="col-9" name="user_name" type="text" placeholder="Ingrese su nombre" onChange={onChange} required />
              </Form.Group>
              <Form.Group controlId="user_email" className="d-flex align-items-center">
                <Form.Label className="col-3">Correo*</Form.Label>
                <Form.Control className="col-9" name="user_email" type="email" placeholder="Ingrese su correo" onChange={onChange} required/>
              </Form.Group>
              <Form.Group controlId="user_phone" className="d-flex align-items-center">
                <Form.Label className="col-3">Teléfono</Form.Label>
                <Form.Control className="col-9" name="user_phone" type="text" placeholder="Puede ingresar su teléfono, es opcional" onChange={onChange}/>
              </Form.Group>

            </Col>
            <Col xs={12} md={{span:3}}>
              <img src="/icons/Asset 44.png" className="w-100" />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className="justify-content-center w-100 mb-5">
            <Col xs={12} md={4}>              
              <button type="submit" className="btn btn-custom-blue w-100">
                Seguir cotizando <img src="/icons/Flecha boton2.png"/> 
              </button>
            </Col>
          </Row>
        </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}

export default UserModal;
