import React, {useState, useEffect} from 'react';
import { Row, Col} from 'react-bootstrap';
import "../styles/home.scss";

function Prototyping() {

  return (
    <div className="prototyping pb-5">
      <Row className="">
        <Col xs={{span:12}} md={{span:11,offset:1}}>
          <div className="banner">
            <h1>PROTOTIPADO</h1>
            <img src={"/images/prototipado_banner.png"}></img>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col xs={{span:6, offset:3}} md={{span:2,offset:1}}>
          <img src={"/images/Prototipado_1.png"} className="w-100"></img>
        </Col>
        <Col xs={{span:12}} md={{span:7,offset:1}}>
          <p className="text-orange m-0">Prototipado</p>
          <h2>Manufactura de tarjetas electrónicas</h2>
          <p>Nuestra especialidad es el diseño, manufactura y ensamble de tarjetas electrónicas. Apoyamos a las distintas empresas a la creación de prototipos electrónicos funcionales, desde su circuitería hasta su puesta en marcha en campo, cumpliendo con las normas que exige la industria electrónica.</p>
          <p>Contamos con distintos métodos para la manufactura de tarjetas electrónicas: revelado y maquinado mediante router de precisión, dependiendo de la necesidad y volumen del proyecto.</p>
          <p>Nuestra cartera de servicios de prototipado consta de:</p>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs={12} md={{span:10, offset:1}} className="p-0">
          <Row>
            <Col xs={12} md={4}>
              <div className="gray-container h-100">
                <p className="text-blue text-center">Manufactura de tarjetas electrónicas<br/> sin componentes (PCB)</p>
                <img src={"/icons/Asset 4.png"} className="icon"></img>
                <ol type="A">
                  <li>1, 2 y 4 capas.</li>
                  <li>Material FR4.</li>
                  <li>Color de Solder Mask: azul, verde, rojo y blanco.</li>
                  <li>Grosor de PCB: 1.2, 1.6 y 2 mm.</li>
                  <li>Acabado HASL, PBFree y ENIG.</li>
                </ol>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="gray-container h-100">
                <p className="text-blue text-center">Ensamble de<br/> tarjetas electrónicas.</p>
                  <img src={"/icons/Asset 5.png"} className="icon"></img>
                  <ol type="A">
                    <li>Línea de SMT para producción de prototipos y bajo volumen.</li>
                    <li>Soldadura selectiva.</li>
                    <li>Horno de Reflujo de 8 y 12 zonas.</li>
                    <li>Máquina de montaje con capacidad para componentes desde 0402 hasta 24 mm.</li>
                  </ol>
              </div>
            </Col>
            <Col xs={12} md={4} >
              <div className="gray-container">
                <div className="d-flex align-items-center">
                    <img src={"/icons/Asset 6.png"} className="icon"></img>
                    <p className="text-blue text-left ml-3 horizontal-limit">Pruebas funcionales para la validación de operación.</p>
                </div>
              </div>
              <div className="gray-container mt-3">
                <div className="d-flex align-items-center">
                    <img src={"/icons/Asset 7.png"} className="icon"></img>
                    <p className="text-blue text-left ml-3 horizontal-limit">Pruebas funcionales para la validación de operación.</p>
                </div>
              </div>
              <a className="btn btn-custom w-100 mt-3" href={"/prototipado"}>
                Ir a cotizador <img src="/icons/Flecha boton2.png" />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-5 align-items-center">
        <Col xs={{span:12}} md={{span:7,offset:1}}>
          <p className="text-orange m-0">Prototipado</p>
          <h2>Diseño e Impresión 3D</h2>
          <p>Con el incremento de capacidades y la evolución que demanda la industria, la impresión 3D grado profesional e industrial se ha vuelto una herramienta y una vital necesidad para reducir los costos.</p>
          <p>Nuestra cartera de servicios de Diseño e Impresión 3D consta de:</p>
        </Col>
        <Col xs={{span:6, offset:3}} md={{span:2,offset:1}}>
          <img src={"/images/Prototipado_2.png"} className="w-100"></img>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs={12} md={{span:10, offset:1}} className="p-0">
          <Row>
            <Col xs={12} md={4}>
              <div className="gray-container h-50">
                <div className="d-flex align-items-center">
                    <img src={"/icons/Asset 8.png"} className="icon"></img>
                    <p className="text-blue text-left ml-3 horizontal-limit">Diseño y servicio de impresión 3D de partes productivas.</p>
                </div>
              </div>
              <div className="gray-container mt-3 h-50">
                <div className="d-flex align-items-center">
                    <img src={"/icons/Asset 9.png"} className="icon"></img>
                    <p className="text-blue text-left ml-3 horizontal-limit">Impresión 3D de grado industrial (alta precisión y repetibilidad).</p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="gray-container h-50">
                <div className="d-flex align-items-center">
                    <img src={"/icons/Asset 10.png"} className="icon"></img>
                    <p className="text-blue text-left ml-3 horizontal-limit">Impresión 3D para patrones de moldes y/o prototipos.</p>
                </div>
              </div>
              <div className="gray-container mt-3 h-50">
                <div className="d-flex align-items-center">
                    <img src={"/icons/Asset 11.png"} className="icon"></img>
                    <p className="text-blue text-left ml-3 horizontal-limit">Impresión 3D de gran formato.</p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="gray-container">
                <p className="text-blue text-center">Impresión 3D de amplia<br/> gama de materiales</p>
                <img src={"/icons/Asset 12.png"} className="icon"></img>
                <p>(PLA- ABS – ASA – PETG – Acrílico – Fibra de Carbono – Nylon – Kevlar - PC – TPU/TPE – Poliéster – entre otros).</p>
              </div>
              <a className="btn btn-custom w-100 mt-3" href={"/prototipado"}>
                Ir a cotizador <img src="/icons/Flecha boton2.png" />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-6 align-items-center">
        <Col xs={{span:6, offset:3}} md={{span:2,offset:1}}>
          <img src={"/images/Prototipado_3.png"} className="w-100"></img>
        </Col>
        <Col xs={{span:12}} md={{span:7,offset:1}}>
          <p className="text-orange m-0">Prototipado</p>
          <h2>Metalmecánica.</h2>
          <p>Uno de los complementos con los que contamos para la producción de prototipos a nivel profesional es la manufactura metalmecánica.</p>
          <p>Nuestro centro de maquinado tiene la capacidad para realizar:</p>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs={12} md={{span:10, offset:1}} className="p-0">
          <Row>
            <Col xs={12} md={4}>
              <div className="gray-container h-100 d-flex align-items-center">
                <div className="w-100">
                  <p className="text-blue text-center">Jigs</p>
                  <img src={"/icons/Asset 13.png"} className="icon"></img>
                  <p className="text-blue text-center">Fixturas</p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="gray-container h-40">
                <div className="d-flex align-items-center">
                    <img src={"/icons/Asset 14.png"} className="icon"></img>
                    <p className="text-blue text-left ml-3 horizontal-limit">Patrones.</p>
                </div>
              </div>
              <div className="gray-container mt-3 h-40">
                <div className="d-flex align-items-center">
                    <img src={"/icons/Asset 15.png"} className="icon"></img>
                    <p className="text-blue text-left ml-3 horizontal-limit">Corte y grabado laser.</p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="gray-container h-75">
                <p className="text-blue text-center">Estructuras con materiales compuestos (fibra de carbono).</p>
                <img src={"/icons/Asset 16.png"} className="icon"></img>
              </div>
              <a className="btn btn-custom w-100 mt-3" href={"/prototipado"}>
                Ir a cotizador <img src="/icons/Flecha boton2.png" />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Prototyping;
